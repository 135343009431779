/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet Landscape

Needed to change the menu styling from mobile to desktop

******************************************************************/

.fa-phone-square {display:none;}
.dontdisplay {display:inline-block;}

h1, .h1 { font-size: 2em;}
h2, .h2, .bigger, .topBlock > div p {font-size: 1.4em;}


/*************************
Colours
*************************/

.white { color: $white; }


/*********************
LAYOUT & GRID STYLES
*********************/
.row {
	padding: 2em 0;
}
/* Reduce padding on some rows*/
#breadcrumbs .row {padding: 1em 0;}

/* Restrict width on some rows and content areas that behave like rows*/
#bottomBlocks .block, body.Landing #content .row, body.Accommodation #content .row, body.Gallery #content .row {
        max-width: 1200px;
    }


/*********************
SPRITE
*********************/
		


/*********************
HEADER STYLES
*********************/

body.Home .header {
	/*height: 112.09px;*/
}

.header {	

	.clearHeader {
        .row {min-height: 90px;}
    }

    .darkHeader {
        .row {min-height: 80px;}
    }

}

.header {

	    .row {
		    position: relative;
	    }

	    #logo, #accountMenu {
            transform: none;
	    }

        #logo {
            left: 0%;

        }

	    #accountMenu {
			top: 8%;
            .button {margin-left:0.5em}
	    }

        #mainMenu {
            display: inline-block;
            position: absolute;
            right: 0%;
            bottom:0%;
        }
     		
}
	


/**************************
HERO - Home
***************************/
body.Home #imageslider {max-height: 570px; overflow:hidden;}

body:not(.Home) #hero {

    .topBlock > div:last-of-type {padding: 2em 3.5em; }
	
    
}

/*********************
NAVIGATION STYLES
*********************/

/*#touchMenu*/
.menu-btn {display:none;}

/*.menuWrap {
	width: 74.30939226%;
}*/
#mainMenu {
	/*padding: 1em 0 0.5em 0;*/
}
.topMenu {
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    float: right;
    clear: none;


    a.toggleMenu {
        display: none;
    }

    .nav {
        position: relative;
        width: auto;
        border: 0;


        > li {
            display: inline-block;
            padding: 0 0.6em;

            > a {
                &:hover, &:focus {
                    background: transparent;
                }

                &.parent:after {
                    content: "\f107";
                    font-family: FontAwesome;
                    display: block;
                    float: right;
                    font-size: large;
                    padding-left: 0.3em;
                    line-height: 1.5;
                }
            }
            /*Prominent contact link*/
            &:first-child {
                display: none;
            }

            /*&:last-child {
                > a {
                    background: $color-secondary;
                    @include border-radius($small-border-radius);
                    padding: 0.5em 1em;
                    @include transition(all 0.5s ease-in-out);

                    &:hover, &:focus, &:active, &.activerootmenulink {
                        background: darken($color-secondary, 15%);
                        @include transition(all 0.5s ease-in-out);
                        color: $white;
                    }
                }
            }*/
            /*turn some of the main nav items OFF for public*/
            /*&:first-child > a{
				display:none;
			}
			
			&:nth-child(6) {
				display:none;
			}
			&:nth-child(7) {
				display:none;
			}
			*/
        }

        li {
            position: relative;

            a {
                padding: 0.5em 0em;
                background: none;
                border-bottom: none;


                &.parent:before {
                    display: none;
                }
            }
            /* highlight current page */
            a.activerootmenulink {
                color: $color-primary;
            }

            &:first-child {
            }

            &:last-child {
            }
            /*
			plan your menus and drop-downs wisely.
			*/
            ul,
            ul.sub-menu,
            ul.children {
                position: absolute;
                z-index: 9999;
                left: -9999px;
                border-top: 2px solid $color-primary;

                li {

                    a {
                        padding: 0.5em 0.5em;
                        display: block;
                        width: 200px;
                        border-top: none;
                        border-radius: 0;
                        margin-right: 0;


                        &:hover,
                        &:focus {
                            border-top: none;
                        }

                        &:link {
                        }
                    }

                    &:first-child {
                    }

                    &:last-child {

                        a {
                        }
                    }
                    /*
					if you need to go deeper, go nuts
					just remember deeper menus suck
					for usability.
					*/
                    ul {
                        border-top: none;
                    }
                }
            }
            /* showing sub-menus */
            &:hover ul {
            }
        }
        /* end .menu ul li */
    }
    /* end .nav */
} /* end .topMenu */

.nav > li.hover > ul { left: 0;}
.nav li li ul { left: -9999px; z-index: 99; position:absolute; }
.nav li li.hover ul { left: 100%; top: 0; z-index: 99; position:absolute; }

/* active state on home - not sure where else to put this for now*/

/*********************
CONTENT STYLES
*********************/

.list { 
    
    li {
      display:inline-block;
      vertical-align:top;
      width: 44%;
   }
}

/*********************
BLOCK LAYOUT
**********************/	


.columnlayout {	
	> div, li {	width: 50%;	}

}

/*Top blocks inner pages*/

body:not(.Home) .topBlock {	
	> div {	
        width: 50%;	
        align-self:center;
        overflow: hidden;

        img {min-height:100%;width:auto;}
	}

    max-height: 445px;
    overflow: hidden;

    
}

 /* Landing page blocks */
    body.Landing #leftcol {
        > div {
           &:first-of-type { width:100%;}
           > div {text-align:center;}
           > div li {text-align:left;}
        }
    }


    /*Bottom Blocks*/

    #bottomBlocks .block {
        margin-bottom: 2em;
        margin: 0 auto;
    }

    /*layout needed for list items */
    /*#bottomBlocks #block_26 {

        ul {
           
            li {
                display:inline-block;
                width: 48%;
                margin-bottom: 0.5em;

            }
        }

    }*/

    /*spacing needed for upcoming events blocks & landing page blocks*/
    #wrapNews.columnlayout, body.Landing #leftcol {
       justify-content: space-between;
       > article, > div {	width: 46%;	}
    }

	

	
/*Full width slider */
body.Home #hero {
	overflow:hidden;
	.slick-slider { }
}

ul.responsive3 {
	max-height: none;
	.slick-list {
			
		.slick-track {
				
			.slick-slide {
				/*height:500px;*/
				
				&:before {background-color: rgba(0, 0, 0, 0.25); }
				
				img {
                        /*height: 420px;*/
                    }	
				.slideshow_caption {  margin: 0 auto;padding:2em; max-width: 90%; }
			}
				
		}
			
	}
}

.slick-slider .slick-active img{ margin-left: 0;}

.slick-prev, .slick-next { top: 60%; }
.slick-next { right: 50px; }
.slick-prev { left: 50px; }
.slick-slider .slick-track, .slick-slider .slick-list { -webkit-perspective: 1000px; }



 /*************************
STICKY BACK TO TOP
*************************/
            .cd-top {
                height: 60px;
                width: 60px;
                right: 30px;
                bottom: 30px;
            }

