/******************************************************************
Site Name:
Author:

Stylesheet: Base Mobile Stylesheet

Be light and don't over style since everything here will be
loaded by mobile devices. You want to keep it as minimal as
possible. This is called at the top of the main stylsheet
and will be used across all viewports.

******************************************************************/

/*********************
GENERAL STYLES
*********************/
body {
	font-family: $sans-serif;
	font-size: 97.5%;
	line-height: 1.6;
	color: $black;
}

.fa-phone-square {display:block;}
.dontdisplay {display:none;}

/********************
BODY CLASSES
style a page via class
********************/

.home {}    /* home page */


/*********************
LAYOUT & GRID STYLES
*********************/

.row {
	margin: 0 auto;
	width: 96%;
	padding: 1em 0;
}

/* Turn off padding on some rows*/
.header .row {padding: 0 0;}

/*********************
LINK STYLES
*********************/

a, a:visited {
	color: $link-color;

	/* removing text decoration from all headline links */
		text-decoration: none;

	/* on hover */
	&:hover, &:focus {
		color: $link-hover;
	}

	/* on click */
	&:active {

	}

	/* mobile tap color */
	&:link {
		/*
		this highlights links on iPhones/iPads.
		so it basically works like the :hover selector
		for mobile devices.
		*/
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	}
}

/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/

h1, .h1, h2, .h2, h3, .h3, .siteName {
	
	a {
		text-decoration: none;
	}
}

.cursive {font-family:$cursive; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, .cursive, .siteName, .siteName span, .siteName strong, h3, .h3, thead {font-weight: 400;line-height: 1.4;}
h3, .h3, h4, .h4,.siteName strong, .topMenu .nav > li > a, thead, #accountMenu .h2{font-weight: 700;}
h1, .h1, h2, .h2, h3, .h3, h5, .h5, h6, .h6, .slideshow_caption .h1, .slideshow_caption .h2 {margin:0.35em 0;}
#accountMenu .h2, #address .h2 {margin:0em 0;}

h3, .h3, h4, .h4, .siteName strong, .topMenu .nav > li > a, thead, .slideHeading, #message p {text-transform:uppercase;}

h3, .h3, h4, .h4,.siteName strong, .topMenu .nav > li > a, thead {letter-spacing:1.5px;}

h1, .h1, .phone { font-size: 1.6em;}
h2, .h2, .slideDescription, body.Home .list li, .bigger, .topBlock > div p, .bigger, #rightcol blockquote{font-size: 1.2em;}
h3, .h3, #breadcrumbs .siteName strong, .accountMenu {font-size: 1em;}
h3, .h3 {
    margin-bottom:1em;
    &:after {
            content: "";
            width: 80px;
            height: 2px;
            background-color: $color-primary;
            display: block;
            margin: 0.5em 0;
       
    }
	&.text-center:after {margin: 0.5em auto;}
}
body.Landing #leftcol div h3:after, body.Landing #leftcol div .h3:after {margin: 0.5em auto;  }
body:not(.Home) #bottomBlocks div h3:after, body:not(.Home) #bottomBlocks div .h3:after {margin: 0.5em auto; background-color: $white; }
.h3.phone:after {display:none;}

smtext, #breadcrumbs, thead, .respond-form label, h4, .h4, h5, .h5, h6, .h6 { font-size: 0.90em; }

#message p {letter-spacing: 1px;}

/*************************
Colours
*************************/
.white, body.home .clearHeader #accountMenu,
body.Home .clearHeader, body.Home .clearHeader .menu-btn, body.Home .clearHeader #accountMenu a, body.Home .clearHeader .topMenu .nav > li > a,
.topMenu .nav li li a,
body.Home #hero, .slideshow_caption p.h3, .topBlock > div,
#footer #facebook a, #copyright, #footer, #footer a, .cd-top i, .slideshow_caption a.button, a.button.primary, a.button.secondary, a.button.tertiary, a.button, .button, a.button:hover, a.button.secondary:hover, .slick-slider button:before,
body:not(.Home) #bottomBlocks, body:not(.Home) #bottomBlocks .h3 {
    color: $white;
}

body.Home .clearHeader #accountMenu a span {color: $white!important;}

.green, .darkHeader #accountMenu, .darkHeader .phone, body.Home #content ul li::before, ul.list li:before, blockquote:before, body.Home .columnlayout h2 a:hover, h3, .h3,  
.respond-form h2, .fa-li
{color: $color-primary;}

.black, .topMenu .nav > li > a, a.button, button
{color: $black; }

.orange, #rightcol blockquote, #rightcol blockquote:before {color:$color-secondary;}

/* all placeholders*/

::-webkit-input-placeholder {color: #999!important;} 
::-moz-placeholder {color: #999!important;} 
::-ms-placeholder {color: #999!important;}  
::placeholder {color: #999!important;} 
::-webkit-datetime-edit {color: #999!important;} 



/*************************
Turn off printable items
*************************/	
	.printable {display: none;}
	

/*********************
SPRITE
*********************/
		
	#logo {
		display: block;
		margin: 0 auto;
        width: 118px; height: 65px;
        background: transparent url('../images/logo.png') no-repeat center top;
		background-size:118px 65px;
        @include transition(all 0.2s ease-in-out);
	}

    body.Home .clearHeader{
        #logo { 
            background-image: url('../images/logo_white.png');
            width: 164px; height: 90px;
		    background-size:164px 90px;
            @include transition(all 0.2s ease-in-out);
        }
    }


/*********************
HEADER STYLES
*********************/

#message {
    background-color: $color-secondary;
    padding: 0.5em 1em;
    @include transition(all 0.2s ease-in-out);

    > p {
        margin: 0 0;
    }
}

/* STICKY NAV - This is where the header is set to be sticky. 
We can also change colour and size here - effects all take place onScroll (waypoints triggers this) */

.header {	

	.clearHeader {
		position: relative;
		background-color: transparent;
		@include transition(all 0.2s ease-in-out);
		
	}
	 
	.darkHeader {
		position: fixed;
		background-color: $white;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
		@include transition(all 0.2s ease-in-out);
        top: 0;
	}
	
}

body.Home .clearHeader {position: fixed;}

/* position header elements as needed */

.header {

    .row {min-height: 70px;}
    
    position: relative;
    z-index: 999999999;
    

    .menu {width:100%;}

    .menu-btn, #logo, #accountMenu{
		position: absolute;
	}

    #accountMenu {
		right: 2%;
        @include center(false, true);
	}
    
    .menu-btn {
		left: 2%;
        @include center(false, true);
	}

    #logo {
		@include center(true, false);
		top: 0em;
	}

    #mainMenu {
        display:none;
    }

    
}



/***********************
HERO 
************************/
body.Home #hero {position:relative;}
body.Home #hero .row { @include center(true, true); width: 90%;}


body:not(.Home) #hero {
    background-color: $color-primary;

    .topBlock > div:last-of-type {padding: 2em; text-align: center;}
	
    
}
	
/*********************
NAVIGATION STYLES
*********************/
#touchMenu,
.menu-btn {display:block;}

#mainMenu {
    display:none;
}

#pushMenu {
    .phone span {display:block;}
}

.topMenu, #pushMenu {
	/*list-style:none;
	float: right;
	clear: right;*/
		
		
		a.toggleMenu {
			width: 100%; 
			padding: 2% 4%;
			text-transform: uppercase;
			margin-bottom: 0;
				span {
					display: block; 
					width:auto; 
					float:right;
					padding-left: 0.5em;
				}
					
		}
		
		.nav {
			/*margin-top:0;
			position: absolute;
			width: 96%;
			z-index: 99999;*/
		
				li {
					position: relative;
					text-align: left;
					a {
						display: block;
						text-decoration: none;
						padding: 0.5em 0.5em;
						background: $black;
						border-bottom: 1px solid rgba(130, 154, 193, 0.21);
				
						&:hover, &:focus {
							background: lighten($black, 5%);
						}
						&.parent:before {
							content: "\f107";
							font-family: FontAwesome;
							display: block;
							float: right;
							font-size: large;
							padding-right: 0.5em;
							line-height: 1.2;
						}
					}
					&.hover {
						> a.parent:before {
							content: "\f106";									
						}
					}
			
					&:first-child {}
			
					&:last-child {}
			
					/* Sub-menu level 2 */
					ul,
					ul.sub-menu,
					ul.children {
						margin-top: 0;
						border-top: 0;
						display: none;
						z-index: 8999;
			
						li {
			
							a {
								padding-left: 1em;
								background: lighten($black, 10%);
								border-bottom: 1px solid rgba(130, 154, 193, 0.21);
											
								&:hover,
								&:focus {
									background: lighten($black, 15%);
								}			
								&:link {}
								
								
							}
			
							&:first-child {}
			
							&:last-child {}
							
							/* Sub-sub-menu level 3	*/
							ul {
													
								li {
					
									a {
										padding-left: 1.5em;												
										background: lighten($black, 20%);
										&:hover,
										&:focus {
											background: lighten($black, 25%);
										}
									}
									
									/* Sub-sub-menu level 4	*/
									ul {
															
										li {
							
											a {
												padding-left: 2em;
												background: lighten($black, 30%);
												
											}
							
										}
							
									}/* end level 4	*/
					
								}
					
							}/* end level 3	*/
			
						}
			
					}/* end level 2	*/
					
					/* Hover effects */
					&:hover ul {
						
					}
					
			
				} /* end .menu li */
			
			
		
		} /* end .nav */

        .nav > li.hover > ul { left: 0; display: block;}
        .nav li li.hover > ul { left: 100%; top: 0; display: block; }

}/* end .topMenu */





/*********************
CONTENT STYLES
*********************/

/* content area all inner pages */
#content, #homeBlocks, #bottomBlocks  {background:$white;}

ul.list { 
    padding: 0em 0em 1em 0em;
    list-style: none;

    li {
      margin-bottom: 0.75em;
      line-height: 1.6;
      position:relative;
      margin-left:5%;

      &:before {
          content: "\f06c";
          font-family: "FontAwesome";
          display: block;
          position:absolute;
          left:-1.5em;
          font-size: large;
          line-height: 1.6;
      }

   }
}

	
			p {}


			ul, ol, table, dl {}

			ul, ol {

				li {}

			}


			ol {

				li {}

			}

			blockquote {
				margin: 0 0 0 0;
				text-align: left;
								
			}
			
			blockquote {
				padding: 1em;
				p {
					padding-left: 2em;
					margin-top: 0;
				}
				h5 {
					margin: 0.4em 0;
				}
								
				&:before {
					content: "\f10d";
					font-family: FontAwesome;
					display: block;
					float:left;
					padding-right: 0.5em;
					line-height: 1.2;
									
				}
				
				a {
					text-decoration: none;
				}
				
				em{
					text-align: right;
					}

			}

			dl {}

			dt {}

			dd {
				margin-left: 0;
				font-size: 0.9em;
				color: #787878;
				margin-bottom: 1.5em;
			}

			img {
				/*margin: 0 0 1.5em 0;*/
				max-width: 100%;
				height: auto;
			}


	#breadcrumbs { 
		border-bottom: 1px dotted lighten($black, 70%);
				
		> div div {
			display: inline;
		}
	}
	
	
/*********************
HOME STYLES
*********************/

/*#content {


    #leftcol {

        > div {
            background: $white;
            margin-bottom: 2em;
            padding: 0;

            > div {

                div {
                    padding: 1em;
                    position: relative;
                    
                }

                > div.last {
                    padding: 0em;
                    overflow: hidden;
                    height: 400px;

                    img {
                        min-height: 420px;
                        max-width: inherit;
                        right: 0;
                        position: absolute;
                        z-index: 0;
                    }

                    a.button {
                        @include center();
                    }
                }
            }
        }
    }
}*/




/*********************************
 BLOCKS
*********************************/

.block {margin-bottom:1em;}

    .columnlayout, body:not(.Home) .topBlock, body.Landing #leftcol {
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 100%;
        padding-left: 0;

        /*turn off basic li styling*/
        padding: 0 0 0em 0;
        /*li:before {display:none;}
        li {margin-bottom: 0em; padding-left:0em;}*/

        > div, > li, article {
            display: inline-block;
            vertical-align: top;
            flex: 1 1 auto;
            flex-grow: 0;
            width: 100%;

            > div {
                width: auto;
            }
        }
    }

  
    /*upcoming events blocks & landing page blocks*/
    #wrapNews.columnlayout, body.Landing #leftcol {
      
       > article, > div {	text-align:center; width: 100%;	}
    }

    /* RIGHT BLOCKS */
    #rightcol {
        .block {

            ol, ul {
                padding: 0em 0em 1em 0em;

                li {
                    list-style: none;
                    min-height: 40px;
                    line-height: 1.6;
                    margin-bottom:0.25em;

                    a {
                        border: 1px dotted $black;
                        padding: 0.5em;
                        display: block;
                        text-decoration: none;
                        background:$white; 
                    }
                    a:hover, a.activesibling {background:darken($white, 5%); color:$color-secondary;}
                }

                li  {
                    a:before {
                        content: "\f105";
                        font-family: "FontAwesome";
                        display: block;
                        float: left;
                        font-size: large;
                        padding-right: 0.5em;
                        padding-bottom: 1em;
                        line-height: 1.2;
                    }
                }
            }
        }
        /* end .block */
    }
    /* end #rightcol */


    /*TOP BLOCKS INNER PAGES*/
  
    body:not(.Home) #topblocks {

        background-color: darken($white, 10%);

        div.block { 
            max-height: 350px;
		    position: relative;
            overflow:hidden;
            margin-bottom:0;

            > p {margin:0 0;}

            img {
              /*max-height: 500px; 
              height: 80vh;
              max-width: none;*/
              width: auto;
              height: auto;
            }
		
		    .caption {
			    @include center();
                /*background-color:rgba(255, 255, 255, 0.6);*/
			    width: 95%;
			    text-align: center;
                padding: 1em;
		    }
        }

    }
   







	
/******************************************************************
FORM STYLES
******************************************************************/

/*Gift Voucher Captcha*/
body#body_464{

    .g-recaptcha > div{
        margin: 0 auto;
    }

  

}

    .respond-form {
        margin: 0em 0em;
        padding: 0%;
        min-width: 190px;
        background-color: transparent;
    }

    .respond-form, #address {
        /* not nested as not all forms contain a table */
        table {
            width: 100%
        }

        form {
            margin: 0 0;

            li {
                list-style-type: none;
                clear: both;
                margin-bottom: 0.7335em;

                label,
                small {
                    display: none;
                }
            }

            fieldset {border:0;}

            input[type=text],
            input[type=email],
            input[type=url],
            textarea,
            select {
                padding: 1em;
                background-color: $white;
                border-color: $color-primary;
                margin: 0 0 1em 0;
                display: table-cell;
                width: 100%;
                max-width: 100%;
                border: 2px solid $color-primary;

                &:focus {
                    
                }
                
            }

            input[type=checkbox] {
                margin-right: 5px;
            }

            input[name="180_LeaseEnquiry"] {
                margin-bottom: 1em;
            }


            .required {
                background-position: right top;
                background-repeat: no-repeat;
            }

            textarea {
                height: 150px;
            }

            img {
                float: left;

                &.fullwidth {
                    max-width: 100%;
                }
            }
        }
    }

    #address form {
        margin: 0 auto;
        input[type=email] {width:auto;padding: 0.5em;}
        input[type="submit"] {min-height: 36px;}
    }

    body.Landing {
        .respond-form {
            background: transparent;

            form {
                input[type=text],
                input[type=email],
                input[type=url],
                textarea,
                select {
                    background-color: $color-quaternary;
                }
            }
        }
    }
    /*********************
LEFT COLUMN
*********************/
    #leftcol {
        padding-bottom: 0em;

        #div307 {
            div {
                text-align: center;
            }
        }
    }
    /*********************
RIGHT BLOCKS
*********************/
    #rightcol {
      

        .block {
            .respond-form, #newsletter {
                form {
                    width: 100%;
                }
            }



            ol, ul {
                padding: 0em 0em 1em 0em;
                margin-bottom: 2em;

                li {
                    /*border-bottom: 1px dotted lighten($black, 70%);*/
                    display: block;
                    /*padding: 0.3em 0 0.3em 0em;*/
                    line-height: 2;

                    &:before {
                        line-height: 2.5;
                    }

                    a {
                        text-decoration: none;
                        display: block;
                    }
                }
            }
        }
        
    }
    
    /*********************
NEWS LISTING
*********************/
    #listing {
        list-style: none;
        margin: 0;
        border-bottom: 1px dotted lighten($color-quaternary, 50%);
        padding: 1em 0;

        h2 {
            margin: 0;
        }

        p {
            margin-top: 0.25em;
        }

        &:last-child {
            border-bottom: 1px dotted lighten($color-quaternary, 50%);
        }
    }



    div.listingImg {
        min-height: 100px;
    }

    div.caseStudy {

        img {
            max-width: 318px;
            height: auto;
        }
    }

/*********************
GALLERY
*********************/
    #gallery {
        display: table;
        margin: 0em auto;
        padding: 0 0 0 0;

        li {
            list-style: none;
            width: 45.5%;
            margin-right: 2%;
            display: -moz-inline-stack;
            display: inline-block;
            zoom: 1;
            *display: inline;
            vertical-align: top;
            border-bottom: 1px dotted lighten($color-quaternary, 50%);
        }

        p {
            display: none;
        }
    }

    .confidence {
        padding: 2em 0;

        .row {
            > div {
                text-align: center;
            }
        }
    }

/*********************
FOOTER STYLES
*********************/
    body:not(.Home) #bottomBlocks, #cta {background:$color-primary url('/library/Client/images/fern.png') no-repeat right bottom; }

    #footer {
        background: $black;
        /*text-align: center;*/
        > div {
           

            i {
                padding-right: 0.25em;
            }

            #copyright {
                .first {
                    a {
                        display: block;
                    }
                }
            }

            nav {
                ul {
                    li {
                        border-bottom: 1px dotted $color-quaternary;

                        a {
                            display: block;
                            line-height: 2;
                        }
                    }
                }
            }

            #privacy {
                text-align: center;

                a {
                    padding: 0 0.5em;

                    &:first-child {
                        border-right: 1px solid $color-quaternary;
                    }
                }
            }
        }
    }
    /* ================================================ */
    /* SLIDESHOWS */
    /* ================================================ */
    .rslides {
        position: relative;
        list-style: none;
        overflow: hidden;
        width: 100%;
        padding: 0;
        margin: 0;

        li {
            position: absolute;
            display: none;
            width: 100%;
            left: 0;
            top: 0;
            /*padding: 2%;*/
            background: $white;
        }

        img {
            display: block;
            height: auto;
            float: left;
            width: 100%;
            border: 0;
        }
    }

    .rslides_nav {
        display: none;
        visibility: hidden;
    }

    .slides1_nav {
        display: none;
        visibility: hidden;
    }
    /* SLIDER */
    .slick-slider {
        padding: 0;
        list-style: none;

        .slick-list {
            margin: 0 auto;

            .slick-track {

                .slick-slide {
                    position: relative;
                	padding:0;

                    .slideshow_caption {
                    }
                }
            }
        }

        button {
            padding: 0.2em;
            height: auto;
            z-index: 99;

            &:before {
                content: "\f104";
                font-family: "FontAwesome";
                height: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        .slick-next:before {
            content: "\f105";
            padding-left: 0.1em;
        }
    }

    .slick-prev, .slick-next {
        top: 55%;
        z-index: 9999;
    }

    .slick-prev {
        left: 1px;
    }

    .slick-next {
        right: 1px;
    }

    .slick-arrow:hover, .slick-arrow:focus, .slick-arrow:active {
        border: none;
    }

    .slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img {
        width: 100%;
    }

    .slick-dots li button:before, .slick-dots li.slick-active button:before {color:$white;}

    /*Full width slider with captions */
    ul.responsive3 {
        margin-bottom: 0;

        .slick-list {

            .slick-track {

                .slick-slide {
					height: 420px;
                    height: 85vh;
					
					&:before {content:""; position:absolute; display: block; width:100%; height:100%; background-color: rgba(0, 0, 0, 0.35); }

                    img {
                        /*height: 420px;*/
                        max-width: none;
                        width: auto;

                        position: relative;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        -webkit-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        transition: transform 6s;
                        position: relative;
                        min-width: 100%;
                        min-height: 100%;
                        z-index: -1;

                    }
					
					p:last-of-type{margin-bottom: 0.5em;}
					
					a.button:first-of-type {animation-delay: 1s;}
					a.button {animation-delay: 1.5s;}
										
                }

                .slick-slide.slick-active  {


                    img {
                        position: relative;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) scale(1.05);
                        -webkit-transform: translate(-50%, -50%) scale(1.05);
                        -ms-transform: translate(-50%, -50%) scale(1.05);
                        position: relative;
                        min-width: 100%;
                        min-height: 100%;
                    }
					
					
										
                }


				.slideshow_caption {opacity:0; visibility:hidden;display:none; @include transition(all 0.5s ease-in-out);}
				.slideshow_caption.activate {background: rgba(0, 0, 0, 0); opacity:1; visibility: visible;display:block; text-align: center; @include transition(all 0.5s ease-in-out);}
				
            }
        }
			
    }


/************************
RESPONSIVE VIDEO WRAPPER
************************/
    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 35px;
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    /*********************
CUSTOMISE colorbox
*********************/
    #cboxCurrent, #cboxNext, #cboxNext, #cboxPrevious, #cboxClose {
        top: 4px;
    }

    #cboxCurrent {
        font-size: 120%;
        line-height: 1.8em;
    }

    #cboxLoadedContent {
        margin-top: 28px;
    }


/*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        z-index: 9999;
        display: inline-block;
        height: 40px;
        width: 40px;
        text-align: center;
        position: fixed;
        bottom: 40px;
        right: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        white-space: nowrap;
        background: $color-tertiary;
        visibility: hidden;
        opacity: 0;
        @include transition(opacity .3s 0s, visibility 0s .3s);


        i {
            margin: auto;
            line-height: 2.5;
            @include center();
        }
    }

    .cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
        @include transition(opacity .3s 0s, visibility 0s .3s);
    }

    .cd-top.cd-is-visible {
        /* the button becomes visible */
        visibility: visible;
        opacity: 1;
    }

    .cd-top.cd-fade-out {
        /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
        opacity: .5;
    }

    .no-touch .cd-top:hover {
        background-color: #e86256;
        opacity: 1;
    }