/******************************************************************
Site Name: 
Author: 

Stylesheet: Super Large Monitor Stylesheet

You can add some advanced styles here if you like. This kicks in
on larger screens.

******************************************************************/
h1, .h1, h2, .h2, h4, .h4, h5, .h5, h6, .h6 {margin:0.5em 0;} 
.slideshow_caption .h1, .slideshow_caption .h2 {margin:0.25em 0;} 
h3, .h3 {margin-bottom:2em;}
.slideHeading {font-size: 3em;}
.slideDescription {font-size: 4em;}

.topMenu .nav > li > a, h3, .h3 {
	font-size: 1.1em;
}

/*********************
LAYOUT & GRID STYLES
*********************/
.row {
	padding: 2.5em 0;
}
	
/*********************
HEADER STYLES
*********************/

.header {

    
	#logo {
		left: 2%;
	}

    #accountMenu {
		right: 0%;
	}

    #mainMenu {
        bottom:7%;
    }
	
}







/**************************
HERO - Home
***************************/

body:not(.Home) #hero {

    .topBlock > div:last-of-type {padding: 2em 5em; }
	
    
}

	


/*********************
NAVIGATION STYLES
*********************/

.topMenu {		
	.nav {		
		> li {
			padding: 0 1em;
		}
	}
}



/*********************
CONTENT STYLES
*********************/

body.Home #content {

    #leftcol {

        > div {

            > div {

                div {
                    padding: 2em;
                    line-height: 1.8;
         
                }
            }
        }
    }
}		




/*********************
BLOCK LAYOUT
**********************/	


.columnlayout {
	
	> div, li {
		width: 25%;
	}
    /*Top row needs border adding seperatley to prevent double borders*/
    > div:nth-child(4) , li:nth-child(4) {
		/*border-top: 1px solid darken($white, 25%);*/
	}
}


 #bottomBlocks {
     .block {margin-bottom:3em;}

 }



 /*Full width slider */
ul.responsive3 {
	
	.slick-list {
		.slick-track {
			.slick-slide {
				/*height:auto;*/
				img {
					/*max-width: 100%;
					width: 100%;
					height: auto;
					max-height: none;*/
				}
			
			}
		}
	}
}