/******************************************************************
Site Name: 
Author: 

Stylesheet: Desktop Stylsheet

This is the desktop size. It's larger than an iPad so it will only
be seen on the Desktop. 

******************************************************************/
body.Home .header .clearHeader {
    #logo { 
        
        width: 255px; height: 140px;
		background-size:255px 140px;
        @include transition(all 0.5s ease-in-out);
    }
}

.header {	

	.clearHeader {

        .row {min-height: 145px;}

        #logo {
            width: 255px; height: 140px;
            background: transparent url('../images/logo.png') no-repeat center top;
		    background-size:255px 140px;
            @include transition(all 0.3s ease-in-out);
        }
		
	}
	 
	.darkHeader {

        .row {min-height: 100px;}

        #logo {
            width: 164px; height: 90px;
            background: transparent url('../images/logo.png') no-repeat center top;
		    background-size:164px 90px;
            @include transition(all 0.3s ease-in-out);
        }

	}
	
	
}


#content {height:auto;}

